import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
const Map = ({ title }) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="md:w-[95%] mx-auto mt-[60px]">
      {
          rpdata?.labels?.general?.titleMap ?
            <h2 className="text-center pb-5">
              {rpdata?.labels?.general?.titleMap}
            </h2>
            :
            <h2 className="text-center pb-5">
              We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
            </h2>
        }
        <Tabs>
          <TabList >
            {rpdata?.dbPrincipal?.location?.map((item, index) => {
              return <Tab key={index}>{item.address}</Tab>;
            })}
          </TabList>
          {rpdata?.dbPrincipal?.location?.map((item, index) => {
              return <TabPanel key={index}><iframe
              loading="lazy"
              title="Cover Locations"
              src={item.url}
              style={{ width: "100%", height: "400px" }}
            /></TabPanel>
            })}
        </Tabs>
      </div>
    </div>
  );
};
export default Map;
